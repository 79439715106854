import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/pentech.hu/documentation-of-integrations/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Accordion = makeShortcode("Accordion");
const AccordionItem = makeShortcode("AccordionItem");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A `}<strong parentName="p">{`payload`}</strong>{` AES-256-CBC kódoláshoz az alábbi példaprogramok nyújthatnak segítséget.`}</p>
    <p>{`A Node.js script futásának példa eredménye:`}</p>
    <pre><code parentName="pre" {...{}}>{`           ALGORITHM:  aes-256-cbc
                 KEY:  9816b04e20e97765f594e2f8fb72f033
------------------------------------------------------------
       original data:  { session: '574c', user: '7d0d' }
       original JSON:  {"session":"574c","user":"7d0d"}
           encrypted:  +3813CZrDICWdSW5plfK6BrS1Xc/atdqSCaWhOTrVsCEbpOzACTVkzKWBQTGakKo:82ef4193d09fe3bf
             payload:  %2B3813CZrDICWdSW5plfK6BrS1Xc%2FatdqSCaWhOTrVsCEbpOzACTVkzKWBQTGakKo%3A82ef4193d09fe3bf
         url decoded:  +3813CZrDICWdSW5plfK6BrS1Xc/atdqSCaWhOTrVsCEbpOzACTVkzKWBQTGakKo:82ef4193d09fe3bf
        decoded JSON:  {"session":"574c","user":"7d0d"}
        decoded data:  { session: '574c', user: '7d0d' }
`}</code></pre>
    <Accordion mdxType="Accordion">
      <AccordionItem title="Javascript (Node.js)" mdxType="AccordionItem">
Ez a script a crypto modul használatával hozza létre a kívánt értékeket.
        <p>{`Link: `}<a parentName="p" {...{
            "href": "https://github.com/pentech-hu/docs/blob/master/aes-256-cbc.js"
          }}>{`https://github.com/pentech-hu/docs/blob/master/aes-256-cbc.js`}</a></p>
        <br />
        <pre><code parentName="pre" {...{
            "className": "language-javascript"
          }}>{`'use strict';

const log = (name, value) =>
  console.log(name.padStart(20, ' ') + ': ', (value || 'falsy'));

const crypto = require('crypto');
const ALGORITHM = 'aes-256-cbc';
const KEY = crypto.randomBytes(16).toString('hex'); // TODO: this should be replaced with the received key!

const data = {
  session: crypto.randomBytes(2).toString('hex'),
  user: crypto.randomBytes(2).toString('hex'),
};
log('ALGORITHM', ALGORITHM);
log('KEY', KEY);
console.log('–'.repeat(60));

log('original data', data);

const phrase = JSON.stringify(data);
log('original JSON', phrase);

/**
 * Encrypts an input string using with the given algorithm, secret key
 * @param input plain text
 * @param algorithm to be used for encryption
 * @param key secret key for encryption
 * @returns {string} encrypted string
 */
const encrypt = (input, algorithm, key) => {
  const initialisationVector = crypto.randomBytes(8).toString('hex');
  let cipher = crypto.createCipheriv(algorithm, key, initialisationVector);
  return \`\${cipher.update(input, 'utf8', 'base64') + cipher.final('base64')}:\${initialisationVector}\`;
};

/**
 * Decrypts an encrypted string with the given secret key
 * @param encrypted
 * @param algorithm to be used for encryption
 * @param key secret key for encryption
 * @returns {string} decrypted plain text
 */
const decrypt = (encrypted, algorithm, key) => {
  const [encoded, initialisationVector] = encrypted.split(':');
  let decipher = crypto.createDecipheriv(algorithm, key, initialisationVector);
  return decipher.update(encoded, 'base64', 'utf8') + decipher.final('utf8');
};

let encrypted = encrypt(phrase, ALGORITHM, KEY);
log('encrypted', encrypted);

let urlEncoded = encodeURIComponent(encrypted);
log('url encoded', urlEncoded);

let urlDecoded = decodeURIComponent(urlEncoded);
log('url decoded', urlDecoded);

let decodedPhrase = decrypt(urlDecoded, ALGORITHM, KEY);
log('decoded JSON', decodedPhrase);

let decoded = JSON.parse(decodedPhrase);
log('decoded data', decoded);
`}</code></pre>
      </AccordionItem>
      <AccordionItem title="PHP" mdxType="AccordionItem">
Az openssl modul használatával.
        <p>{`Link: `}<a parentName="p" {...{
            "href": "https://github.com/pentech-hu/docs/blob/master/aes-256-cbc.php"
          }}>{`https://github.com/pentech-hu/docs/blob/master/aes-256-cbc.php`}</a></p>
        <br />
        <pre><code parentName="pre" {...{
            "className": "language-php"
          }}>{`<?php
    function _log($name, $value){
        echo str_pad($name, 20, " ", STR_PAD_LEFT) . ": " . $value . "\\n";
    }
    $algorithm = 'aes-256-cbc';
    $encryption_key = bin2hex(random_bytes(16));  // TODO: this should be replaced with the received key!

    _log('ALGORITHM', $algorithm);
    _log('KEY', $encryption_key);

    echo str_repeat('–', 60) . "\\n";

    $data = [
        'session' => '93b4',
        'user' => '9542'
    ];

    function encrypt($input, $algorithm, $encryption_key) {
        // openssl_random_pseudo_bytes generates chars invalid to be used in URL, so we use random_bytes as it also generates cryptographically secure pseudo-random bytes
        $initialisation_vector = bin2hex(random_bytes(openssl_cipher_iv_length($algorithm) / 2));
        return openssl_encrypt($input, $algorithm, $encryption_key, 0, $initialisation_vector) . ':' . $initialisation_vector;
    };

    function decrypt($encrypted, $algorithm, $encryption_key) {
        list($encoded, $initialisation_vector) = explode(':' , $encrypted, 2);
        // _log('encoded part', $encoded);
        // _log('iv', $initialisation_vector);
        return openssl_decrypt($encoded, $algorithm, $encryption_key, 0, $initialisation_vector);
    };

    $original_json = json_encode($data);
    _log('original JSON', $original_json);

    $encrypted = encrypt($original_json, $algorithm, $encryption_key);
    _log('encrypted', $encrypted);

    $url_encoded = rawurlencode($encrypted);
    _log('url encoded', $url_encoded);

    $url_decoded = rawurldecode($url_encoded);
    _log('url decoded', $url_decoded);

    $decrypted = decrypt($url_decoded, $algorithm, $encryption_key);
    _log('decoded JSON', $decrypted);

    $decrypted_data = json_decode($decrypted);
    echo 'Finished.';
`}</code></pre>
      </AccordionItem>
    </Accordion>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      